<template>
    <div class="scan-page">
        <scanner @barcodeHasBeenScanned="barcodeScanned($event)"></scanner>

        <!-- Manual entry -->
        <div class="container">
            <div class="row" v-if="!isGradeZ">
                <div class="col-12">
                    <label>Manually enter the IMEI</label>
                </div>
                <div class="col">
                    <div class="form-group mb-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fa fa-search mb-0"></i></span>
                            </div>
                            <input type="text" class="form-control" placeholder="Enter IMEI" v-on:keyup.enter="validateImei()" v-model="imei">
                            <span class="input-clear" v-if="imei" @click="clearImei()"><i class="fas fa-times-circle mb-0"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-auto pl-0">
                    <button class="btn btn-success btn-block d-md-inline-block py-2" @click="validateImei()" :disabled="!imei || validating">
                        <span class="d-md-none"><i class="fa fa-search mr-0"></i></span>
                        <span class="d-none d-md-inline">Search</span>
                    </button>
                </div>
            </div>

            <!-- Validating overlay -->
            <loading-state
                    v-if="validating && !invalidImei"
                    overlay
                    title="Validating IMEI">
            </loading-state>

            <!-- Invalid IMEI -->
            <empty-state
                    v-if="imei && invalidImei"
                    statusIcon="error"
                    :title="`${ imei } <span class='font-weight-light'>is either invalid or the asset has not yet been processed. Try scanning again or check that the asset exists in the admin panel.</span>`">
            </empty-state>

            <!-- Asset states -->
            <asset-states
                    v-if="validImei && !passedDiagnosis && !isGradeZ"
                    :asset="asset"
                    :imei="imei">
            </asset-states>

            <!-- Invalid IMEI -->
            <empty-state
              v-if="passedDiagnosis && !isGradeZ"
              statusIcon="error"
              :title="`<span class='font-weight-light'>The asset with the IMEI</span> ${ imei } <span class='font-weight-light'>has already passed the diagnosis stage. Try searching for another IMEI.</span>`">
                <slot>
                    <button class="btn btn-light" @click="printAssetLabel(asset.id)">Reprint asset label</button>
                    <button class="btn btn-light" v-if="asset.job_id" @click="goToJob()">Go to job</button>
                </slot>
            </empty-state>

            <template v-if="isGradeZ">
                <div class="container pt-0">
                    <div class="card mb-0">
                        <empty-state
                            class="py-3 icon-all-muted"
                            inline
                            small
                            subtitle="Device Triage"
                            title=""
                        >
                        </empty-state>
                    </div>
                </div>

                <div v-if="!asset.triage_complete && !this.completingTriage">
                    <div class="row">
                        <div class="col-12">

                            <!-- Question list -->
                            <div class="form-group" v-if="!asset.triage_complete && asset.triage_questions.length > 0">
                                <div v-bind:key="question.id" v-for="question in asset.triage_questions">
                                    <div class="card card-body pb-0">
                                        <h5>{{ question.text }}</h5>

                                        <ul class="list-group selection-list mb-4">
                                            <li class="list-group-item" :class="{'active': question.response_id === option.id}"
                                                v-bind:key="option.id" v-for="option in question.options"
                                            >
                                                <label class="custom-control custom-radio mb-0">
                                                    <input type="radio" class="custom-control-input"
                                                           :id="option.id" :value="option.id"
                                                           :disabled="asset.triage_complete"
                                                           v-model="question.response_id"
                                                    >
                                                    <label class="custom-control-label mb-0" :for="option.id">{{ option.text }}</label>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <button
                                v-if="!asset.triage_complete"
                                class="btn btn-success"
                                :disabled="!canTriage"
                                @click="completeTriage()">
                                Confirm Triage
                            </button>
                        </div>
                    </div>
                </div>

                <loading-state
                    v-if="this.completingTriage"
                    overlay
                    title="Updating Triage Options">
                </loading-state>

                <empty-state
                    v-if="asset.triage_complete && !this.completingTriage"
                    statusIcon="info"
                    :title="`<span class='font-weight-light'>Asset </span> ${ imei }`"
                    :subtitle="`Triage determined to be ${asset.asset_repair_type}`"
                >
                    <slot>
                        <div v-if="asset.asset_repair_type_tag === 'z-level-2' || gradeZLevel === 2">
                            <p>Please continue with the standard hardware repair process</p>

                            <button
                                v-if="asset.asset_repair_type_tag === 'z-level-2' || gradeZLevel === 2"
                                class="btn btn-info"
                                @click="clearAsset()">
                                Process Another Asset
                            </button>
                        </div>

                        <template v-if="asset.asset_repair_type_tag === 'z-level-1'">
                            <div class="mb-4">Please confirm that this repair has been completed</div>
                            <div>
                                <button
                                    class="btn btn-success"
                                    @click="openLv1Confirm">
                                    Confirm Repair Completed - Upload Photos
                                </button>
                                <button
                                    class="btn btn-danger"
                                    @click="openLv2Confirm">
                                    Software Repair Not Possible - Change to Hardware Repair
                                </button>
                            </div>
                        </template>
                    </slot>
                </empty-state>

            </template>
        </div>
        <confirm-modal
          :confirmTitle="confirmTitle"
          :confirmText="confirmText"
          :confirmKey="confirmKey"
          @actionConfirmed="confirmModalAction($event)"
        ></confirm-modal>
    </div>


</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    props: ['cannotRepairAsset', 'diagnosticsComplete'],

    data() {
        return {
            imei: null,
            invalidImei: false,
            passedDiagnosis: false,
            validating: false,
            validImei: false,
            triageRequired: false,
            gradeZLevel: 0,
            confirmTitle: '',
            confirmText: '',
            confirmKey: null,
            completingTriage: false
        }
    },

        computed: {
            ...mapGetters([
                "asset",
            ]),

            isTriageRequired() {
                return this.triageRequired;
            },

            isGradeZ() {
                // Turning this off for now, without removing the functionality.
                //  They're not currently using Grade Z in this way, but that might change in the future.
                // if (this.asset) {
                //     return this.asset.grade_Z
                // }

                return false;
            },

            canTriage() {
                let canTriage = true;
                this.asset.triage_questions.forEach((question) => {
                    if (question.response_id === undefined
                      || question.response_id === null
                      || question.response_id === 0) {
                        canTriage = false;
                    }
                });

                return canTriage;
            },
        },

        watch: {
            imei: function() {
                this.invalidImei = false;
                this.passedDiagnosis = false;
            }
        },

        mounted() {
            this.clearAsset();

            if (this.diagnosticsComplete) {
                this.displayToast({text: 'Asset diagnostic completed', type: 'success'});
            }

            if (this.cannotRepairAsset) {
                this.displayToast({text: 'Asset marked as "cannot repair"', type: 'warning'});
            }
        },

        methods: {
            ...mapActions([
                "clearAsset",
                "displayToast",
                "loadAssetFromReference",
                "printAssetLabel",
                "completeAssetTriage",
                "setAssetStatus",
                "updateAssetToLevelTwo",
            ]),

            /** Barcode scanned */
            barcodeScanned(barcode) {
                if (typeof barcode.data === 'object') {
                    this.imei = barcode.data.reference;
                } else {
                    this.imei = barcode.data;
                }
                this.validateImei();
            },

            /** Clear the input */
            clearImei() {
                this.imei = null;
                this.validImei = false;
            },

            /** Validate the IMEI */
            validateImei() {
                this.triageRequired = false;
                this.passedDiagnosis = false;
                this.gradeZLevel = 0;
                this.loadAssetFromReference({
                    assetReference: this.imei
                }).then(() => {
                    this.validImei = true;

                    if (this.isGradeZ) {
                        this.triageRequired = !this.asset.triage_complete;

                        if(!this.isTriageRequired) {
                            if (this.asset.asset_repair_type_tag === 'z-level-1') {
                                this.gradeZLevel = 1;
                                // if software- redirect for photos if repair is completed!
                                if (this.asset.status === 'Repair Complete') {
                                    return this.$router.push({
                                        name: 'bulkRepairDiagnostics.photos',
                                        params: {
                                            assetId: this.asset.id
                                        }
                                    });
                                }
                            } else if (this.asset.asset_repair_type_tag === 'z-level-2') {
                                this.gradeZLevel = 2;
                                // hardware - message to go through standard route on website.
                            }
                        }
                    } else {
                        // If the status is Not Yet Received
                        if (!this.passedDiagnosis && (this.asset.status_code === 'not-yet-received' || this.asset.status_code === 'requires-re-repair')) {
                            if (this.asset.label_printed) {
                                if (!this.asset.asset_type || (this.asset.asset_type && this.asset.asset_type === 'Customer Mail-in')) {
                                    return this.$router.push({
                                        name: 'bulkRepairDiagnostics.device',
                                        params: {assetId: this.asset.id}
                                    });
                                }

                                return this.$router.push({
                                    name: 'bulkRepairDiagnostics.confirmProblems',
                                    params: {assetId: this.asset.id}
                                });

                            }

                            // If the asset has already passed diagnosis
                            this.$router.push({name: 'bulkRepairPrintLabel', params: {assetId: this.asset.id}});

                        } else {
                            this.passedDiagnosis = true;
                        }
                    }
                }).catch((error) => {
                    console.error(error);
                    this.invalidImei = true;
                    this.passedDiagnosis = false;
                    this.validImei = false;
                });
            },

            completeTriage() {
                this.gradeZLevel = 0;
                if (!this.canTriage || this.completingTriage) {
                    return;
                }

                this.displayTriageLoading()

                this.completeAssetTriage(this.asset).then((repairType) => {
                    if (repairType === "Level 1 - Software Fix") {
                        this.gradeZLevel = 1;
                        // Redirect to photo page
                    } else {
                        this.gradeZLevel = 2;
                    }
                }).catch(error => {
                    this.displayToast({text: error.response.data.messages, type: 'error'});
                });
            },

            openLv1Confirm() {
                this.confirmTitle = "Is the repair Complete?";
                this.confirmText = "Please confirm that the repair has been completed, and continue on to take photos of the device";
                this.confirmKey = "level1";
                this.$bvModal.show('confirm-modal');
            },

            openLv2Confirm() {
                this.confirmTitle = "Does this asset require a hardware repair?";
                this.confirmText = "Once confirmed, you will need to take this asset through the standard repair process.";
                this.confirmKey = "level2";
                this.$bvModal.show('confirm-modal');
            },

            confirmModalAction($event) {
                this.displayTriageLoading();

                if ($event === 'level1') {
                    this.confirmCompleteLevel1();
                } else {
                    this.confirmCompleteLevel2();
                }
            },

            confirmCompleteLevel1() {
                this.setAssetStatus({assetReference: this.imei, status: 'repair-complete'}).then(() => {
                    this.validImei = true;
                    this.displayToast({text: "Device logged as complete"});
                    this.$bvModal.hide('confirm-modal');
                    return this.$router.push({
                        name: 'bulkRepairDiagnostics.photos',
                        params: {
                            assetId: this.asset.id
                        }
                    });
                })
            },

            confirmCompleteLevel2() {
                this.updateAssetToLevelTwo({assetId: this.asset.id}).then(() => {
                    this.validImei = true;
                    this.gradeZLevel = 2;
                    this.displayToast({text: "Asset Updated"});
                    this.$bvModal.hide('confirm-modal');
                })
            },

            goToJob() {
                this.$router.push({name: `job`, params: {jobId: this.asset.job_id}});
            },

            displayTriageLoading() {
                this.completingTriage = true;
                setTimeout(() => {
                    this.completingTriage = false
                }, 8000)
            }
        }
    }
</script>

<style scoped>

</style>